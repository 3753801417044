import React, { useState } from "react";
import animationData1 from "../../Lotties/Service1.json"
import animationData2 from "../../Lotties/Service2.json"

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { GiBurningDot } from "react-icons/gi";
import { TbSettingsSearch } from "react-icons/tb";
import { FaUsersGear } from "react-icons/fa6";
import { Card, CardHeader, CardBody } from "@material-tailwind/react";
import Lottie from "lottie-react";

export function ServiceTabs() {
  const [selectedTab, setSelectedTab] = useState("recruitment");

  const TABS = [
    {
      logo: <Lottie animationData={animationData1} loop autoplay style={{ width: 50, height: 50 }} />,
      label: "Recruitment",
      value: "recruitment",
      status: 0,
    },
    {
      logo: <Lottie animationData={animationData2} loop autoplay style={{ width: 50, height: 50 }} />,
      label: "Permanent Staffing",
      value: "permanentstaffing",
      status: 1,
    },
  ];

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const renderCards = () => {
    return (
      <div className="px-2 md:px-2 lg:px-4">
        <div className="py-4">
          {selectedTab === "recruitment" && (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                <div className="flex flex-col gap-5">
                  <p className="text-sm text-gray-600 md:mt-8">
                    We do all type of hiring like, IT Staffing, Executive
                    Search, Senior & Middle Level Hiring, Campus Hiring etc. We
                    are committed to provide complete recruitment solutions to
                    our clients basis on their hiring needs.
                  </p>
                  <h2 className="text-md font-bold">
                    We serve clients from a variety of sectors including:
                  </h2>
                  <nav>
                    <ul className="flex flex-col items-start justify-center p-4 text-md text-gray-600">
                      <li className="flex items-center justify-center gap-2">
                        <GiBurningDot color="red"/>
                        <p> IT Staffing</p>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                      <GiBurningDot color="red"/>
                        <p> Executive Search</p>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                      <GiBurningDot color="red"/>
                        <p> Middle Level Hiring</p>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                      <GiBurningDot color="red"/>
                        <p> Temporary Staffing</p>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                      <GiBurningDot color="red"/>
                        <p> Bulk Hiring</p>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                      <GiBurningDot color="red"/>
                        <p> Campus Hiring</p>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="flex items-center justify-center">
                  <img className="w-full" src="./images/service4.jpg" loading="lazy"></img>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-6">
                In your tight schedule where you meet clients, manage your work,
                and do your task you need someone who can help you with your
                business. We help you in searching for those people.
              </p>
            </>
          )}
          {selectedTab === "permanentstaffing" && (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex flex-col gap-2">
                  <h4 className="text-gray-800 font-[500] text-lg sm:text-2xl">
                    Permanent Staffing
                  </h4>
                  <p className="text-sm text-gray-600 mt-6">
                    Permanent staffing is the process of providing required
                    candidates for long-term employment based on specified
                    candidate factors. ARD Manpower Solution accumulates the
                    best fit candidates based on the bar of salary, experience,
                    cultural resonance and job description to ensure complete
                    provision of solution. A permanent staffing solution
                    provider takes charge of finding, filtering, interviewing
                    and placing the required talent in the organization.
                  </p>
                  <p className="text-sm text-gray-600 italic mt-4">
                    Permanent Staffing is the recruitment done on the general
                    level. Here, the candidates are recruited for a basic level
                    job, the candidates are relatively new in the industry.
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <img className="w-full" src="./images/service3.jpg" loading="lazy"></img>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-6">
                In your tight schedule where you meet clients, manage your work,
                and do your task you need someone who can help you with your
                business. We help you in searching for those people.
              </p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center mt-16">
      <Card className="h-full w-full px-4">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
            <Tabs value={selectedTab} className="w-full">
              <TabsHeader>
                {TABS.map(({ label, value, logo }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => handleTabChange(value)}
                    className="flex items-center p-2"
                  >
                    <div className="flex items-center justify-center">
                      {logo}
                      <span className="ml-2 hidden md:block">{label}</span>
                    </div>
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
          </div>
        </CardHeader>
        <CardBody className="px-0">
          <div className="mt-4 w-full">{renderCards()}</div>
        </CardBody>
      </Card>
    </div>
  );
}
