import { Carousel, Typography, Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function ServiceCarousel() {
  const navigate = useNavigate();
  return (
    // <Carousel className="h-[500px] w-full">
    <div className="h-[400px] md:h-[500px] w-full">
         <div className="relative h-full w-full">
        <img
        src="https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="img2"
          loading="lazy"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
          <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-3xl lg:text-4xl"
            >
            Shaping Your Dreams
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 text-sm md:text-xl"
            >
              Giving you the tools and techniques required to increase your overall revenue and help your business grow
            </Typography>
            <div className="flex">
              <Button onClick={() => navigate("/")} size="md" color="red">
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
