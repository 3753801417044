import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Homepage/Home";
import AboutUs from "./Components/Pages/AboutUs";
import Services from "./Components/Pages/Services";
import OurProcess from "./Components/Pages/OurProcess";
import OurClients from "./Components/Pages/OurClients";
import Careers from "./Components/Pages/Careers";
import ContactUs from "./Components/Pages/ContactUs";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/our-process" element={<OurProcess />}></Route>
        <Route path="/our-clients" element={<OurClients />}></Route>
        <Route path="careers" element={<Careers />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
      </Routes>
    </>
  );
};

export default App;
