import { Carousel, Typography, Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function ProcessCarousel() {
  const navigate = useNavigate();
  return (<Carousel className="h-[500px] w-full">
      <div className="relative h-full w-full">
        <img
          src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="img2"
          loading="lazy"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
          <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-3xl lg:text-4xl"
            >
            Unlock Growth Potential
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 text-sm md:text-xl"
            >
              Empowering Your Team with Effective Strategies for Increased Revenue and Business Expansion"
            </Typography>
            <div className="flex">
              <Button onClick={() => navigate("/")} size="md" color="red">
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <img
          src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="img3"
          loading="lazy"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-end bg-black/75">
          <div className="w-3/4 pl-12 pb-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-3xl lg:text-4xl"
            >
             Unlock Growth Potential
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 text-sm md:text-xl"
            >
               Empowering Your Team with Effective Strategies for Increased Revenue and Business Expansion"
            </Typography>
            <div className="flex">
              <Button onClick={() => navigate("/")} size="md" color="red">
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
    
  );
}
