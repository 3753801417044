import React from "react";
import { Button } from "@material-tailwind/react";
import { BiSupport } from "react-icons/bi";
import { AiOutlineSolution } from "react-icons/ai";
import { MdOutlineSource, MdPeople } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import Parallax from "react-next-parallax";

export function Intro() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/services");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="bg-[#252525]">
        <section className="container px-4 py-12 mx-auto">
          <div className="grid content-center lg:grid-cols-3 md:grid-cols-2 gap-4 gap-x-12">
            <div
              data-aos="slide-right"
              className="lg:col-span-1 content-center lg:row-span-2 md:col-span-full"
            >
              <h1 className="text-[22px] font-bold tracking-tight text-white md:text-[26px] lg:text-[28px]">
                Services Of Company
              </h1>
              <p className="mt-4 text-base font-rubik text-white">
                At AVD Staffing, we are dedicated to delivering exceptional HR
                solutions tailored to the low to mid-level management sector.
                Our team is driven by a shared passion for excellence and a
                genuine enthusiasm for providing our clients with the best
                possible service. We offer a range of services designed to meet
                the specific needs of each client, ensuring a personalized
                approach that goes above and beyond expectations.
              </p>
              <div className="">
                <Button
                  className="flex w-28 justify-center items-center rounded-lg  bg-red-900 text-white hover:bg-black  cursor-pointer mt-5"
                  onClick={handleClick}
                >
                  <p className="font-rubik">More</p>
                  <ChevronRight size={15} />
                </Button>
              </div>
            </div>
            {/* Add Paraleex in this part only */}

            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 transition-all duration-200 rounded-lg "
            >
              <Parallax y={[-20, 20]}>
                {/* <Users color="red" size={30} /> */}
                <AiOutlineSolution color="red" size={30} />
                <p className="font-[600] text-xl mt-1 font-mulish text-white">
                  Recruitment Solutions
                </p>
                <p className="mt-4 text-base font-rubik text-gray-400">
                  We include tailored strategies for low to mid-level management
                  positions, including thorough screening and a personalized
                  approach to match client requirements.
                </p>
              </Parallax>
            </div>

            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 rounded-lg"
            >
              <Parallax y={[-20, 20]} className="w-full h-full">
                <MdPeople color="red" size={30} />
                <p className="font-[600] text-xl mt-1 font-mulish text-white">
                  HR Consulting
                </p>
                <p className="mt-4 text-base text-gray-400 font-rubik">
                  At our agency we provide strategic HR advice and solutions,
                  along with assistance in workforce planning and organizational
                  development.
                </p>
              </Parallax>
            </div>
            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 rounded-lg"
            >
              <Parallax y={[-20, 20]} className="w-full h-full">
                <MdOutlineSource color="red" size={30} />
                <p className="font-[600] text-xl mt-1 font-mulish text-white">
                  Candidate Sourcing
                </p>
                <p className="mt-4 text-base text-gray-400 font-rubik">
                  We Utilize an extensive network and target sourcing for
                  qualified candidates, including skill and culture fit
                  assessments for better candidate-client alignment.
                </p>
              </Parallax>
            </div>
            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 rounded-lg"
            >
              <Parallax y={[-20, 20]} className="w-full h-full">
                {/* <Users color="red" size={30} /> */}
                <BiSupport color="red" size={30} />
                <p className="font-[600] text-xl font-mulish mt-1 text-white">
                  Client Support
                </p>
                <p className="mt-4 text-base text-gray-400 font-rubik">
                  We provide a dedicated client support team for personalized
                  service, with regular updates and feedback throughout the
                  recruitment process.
                </p>
              </Parallax>
            </div>
            {/* End Paraleex in this part only */}
          </div>
        </section>
      </div>
    </>
  );
}
