// const URL = "http://localhost:4000";

const URL = "https://avd-staffing.onrender.com";

export function userContact(body) {
  return fetch(`${URL}/api/v1/contact-us`, {
    headers: {
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

// export function carrerForm(body) {
//   return fetch(`${URL}/api/v1/apply`, {
//     headers: {
//       "enctype": "multipart/form-data",
//     },
//     method: "POST",
//     body: JSON.stringify(body),
//   })
//     .then((d) => d.json())
//     .catch((e) => e);
// }


export function carrerForm(body) {
  const formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key]);
  }

  return fetch(`${URL}/api/v1/apply`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => error);
}
