import { Button } from "@material-tailwind/react";
import React from "react";
import Parallax from "react-next-parallax";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

export default function Hero(props) {
  return (
    <div className="container px-4 py-12 mx-auto">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 space-x-2">
        <div className="flex flex-col justify-center  py-8 md:py-16 lg:col-span-7 lg:py-24 xl:col-span-6">
          <div className=" flex max-w-max items-center space-x-2 rounded-full bg-gray-100 p-1">
            <div className="rounded-full bg-white p-1 px-2">
              <p className="text-sm font-medium">We&apos; hiring</p>
            </div>
            <p className="text-sm font-medium">Join our team &rarr;</p>
          </div>
          <h1 className="mt-8 text-3xl font-bold tracking-tight text-black md:text-4xl lg:text-6xl">
            People who care about your growth
          </h1>
          <p className="mt-8 text-lg text-gray-700 font-rubik">
            We believe in more than just filling roles – we're dedicated to
            nurturing talent and fostering long-term success.
          </p>
          <Button
            className="w-1/2 mt-4 bg-red-900 -500 hover:bg-black"
            onClick={() => {
              props.scrollToWelcome();
            }}
          >
            Get Started
          </Button>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:col-span-6">
          <Parallax y={[-10, 10]} className="w-full h-full">
            <img
              className="rounded-md bg-gray-50 object-cover lg:h-[700px] xl:aspect-[16/9]"
              src="./images/hero.png"
              alt=""
              loading="lazy"
            />
          </Parallax>
        </div>
      </div>
    </div>
  );
}
