import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Link } from "react-router-dom";

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    
    {
      question: "What services does AVD Staffing offer?",
      answer:
        "AVD Staffing provides a range of services including IT Staffing, Executive Search, Permanent Staffing, Temporary Staffing, and Payroll Outsourcing.",
    },
    {
      question: "How do I get started with AVD Staffing?",
      answer:
        "Getting started with AVD Staffing is easy. Simply contact us through our website, email, or phone to discuss your staffing needs. Our team will work with you to understand your requirements and provide the best solutions.",
    },
    {
      question: "How can I contact AVD Staffing for more information?",
      answer:
        "You can contact AVD Staffing by visiting our website and filling out the contact form, emailing us at info@avdstaffing.com, or calling our office at [insert phone number]. Our team is ready to assist you with any inquiries.",
    },
    {
      question: " How does AVD Staffing ensure the quality of candidates?",
      answer:
        "We have a rigorous screening and selection process that includes background checks, skill assessments, and thorough interviews. This ensures that we present only the most qualified and suitable candidates for your consideration.",
    },
    // Add more questions and answers as needed
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-gray-100">
      <div>
        {" "}
        <section className="container mx-auto px-4 w-full py-10">
          <div className="bg-white rounded-md py-8">
            <div className="mx-auto py-4 text-center">
              <h2 className="text-[22px] text-center font-bold tracking-tight mx-auto md:text-[26px] lg:text-4xl">
                Frequently Asked Questions
              </h2>
              <div className="h-1 w-1/12 rounded-md bg-red-500 mx-auto mt-2"></div>
              <p className="mt-5 max-w-xl text-base text-center font-rubik leading-relaxed mx-auto px-2">
                Welcome to our FAQs! Here you'll find answers to common
                questions about our services and processes.
              </p>
            </div>
            <div className="mx-auto mt-5 max-w-3xl space-y-4 p-2 md:mt-16">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="cursor-pointer font-rubik rounded-md border border-gray-400 bg-gray-100 shadow-lg transition-all duration-200 px-2"
                >
                  <button
                    type="button"
                    className="flex w-full items-center justify-between px-4 py-5 sm:p-6"
                    onClick={() => handleToggle(index)}
                  >
                    <span className="flex text-lg font-semibold text-black">
                      {faq.question}
                    </span>
                    {openIndex === index ? (
                      <ChevronUp className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  {openIndex === index && (
                    <div className="px-4 sm:px-6 sm:pb-6">
                      <p className="text-gray-500">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <p className="text-base mt-6 text-center text-gray-600">
              Can't find what you're looking for?{" "}
              <Link
                to="/contact-us"
                className="font-semibold text-black hover:underline"
              >
                Contact our support
              </Link>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Faqs;
