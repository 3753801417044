import React, { useState } from "react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { CarrersCarousel } from "../Carousel/CarrersCarousel";
import { toast } from "react-toastify";
import { carrerForm } from "../../api/function";
import FormData from "form-data";

const Careers = () => {
  const [loading, setIsloading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    dob: "",
    email: "",
    phoneNumber: "",
    motherTongue: "",
    gender: "",
    address: "",
    state: "",
    city: "",
    postalCode: "",
    experience: "",
    qualification: "",
    skills: "",
    appliedFor: "",
    message: "",
    file: null,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: file,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsloading(true);

    console.log("Normal formdata:", formData);

    // const formDataToSend = new FormData()
    // formDataToSend.append("fullName", formData?.fullName);
    // formDataToSend.append("email", formData?.email);
    // formDataToSend.append("dob", formData?.dob);
    // formDataToSend.append("motherTongue", formData?.motherTongue);
    // formDataToSend.append("gender", formData?.gender);
    // formDataToSend.append("address", formData?.address);
    // formDataToSend.append("state", formData?.state);
    // formDataToSend.append("city", formData?.city);
    // formDataToSend.append("postalCode", formData?.postalCode);
    // formDataToSend.append("experience", formData?.experience);
    // formDataToSend.append("qualification", formData?.qualification);
    // formDataToSend.append("applied", formData?.applied);
    // formDataToSend.append("skills", formData?.skills);
    // formDataToSend.append("message", formData?.message);

    // if (formData?.file) {
    //   formDataToSend.append("file", formData?.file);
    // }

    // console.log("FORMDATA", formDataToSend);
    carrerForm(formData)
      .then((response) => {
        toast.success(response?.message, {
          className: "text-[12px] !shadow-lg !rounded-xl",
        });
      })
      setFormData({
        fullName: "",
        dob: "",
        email: "",
        phoneNumber: "",
        motherTongue: "",
        gender: "",
        address: "",
        state: "",
        city: "",
        postalCode: "",
        experience: "",
        qualification: "",
        skills: "",
        appliedFor: "",
        message: "",
        file: null,
      })
      .catch((error) => {
        toast.error("Something went wrong!", {
          className: "text-[12px] !shadow-lg !rounded-xl",
        });
        console.error("Error during form submission:", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <Navbar />
      <div className="hidden md:block">
        <CarrersCarousel />
      </div>
      <div className="container px-6 py-8 mx-auto">
        <div
          data-aos="zoom-in"
          className="flex flex-col items-center justify-center"
        >
          <p className="font-bold text-2xl text-black dark:text-blue-400">
            CAREERS
          </p>
          <div className="h-1 w-1/12 bg-red-500 rounded-md"></div>
          <p className="mt-3 text-gray-700 dark:text-gray-400 text-center font-rubik">
            Thank you for your interest in career opportunities with AVD
            Staffing Solution. To request information or send general feedback
            about our recruitment services, please complete the following form.
            We are excited to learn more about your career aspirations and how
            we can assist you in achieving your professional goals.
          </p>
        </div>
        <div
          data-aos="zoom-in"
          className="bg-white border border-4 rounded-lg shadow relative mt-10"
        >
          <div className="flex items-start justify-center p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold">Application Details</h3>
          </div>

          <div className="p-4 space-y-6">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="fullname"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    id="fullname"
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Enter your full name"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="dob"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Date Of Birth
                  </label>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="number"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Enter your number"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="motherTongue"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Mother Tongue
                  </label>
                  <input
                    type="text"
                    name="motherTongue"
                    id="motherTongue"
                    value={formData.motherTongue}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Mother tongue"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="gender"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Gender
                  </label>
                  <select
                    name="gender"
                    id="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    required
                  >
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="address"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Your Address"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="state"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    value={formData.state}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Your State"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="city"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Your city"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="postalCode"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Zip/Postal Code
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Your postal code"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="experience"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Experience (in Years)
                  </label>
                  <input
                    type="number"
                    name="experience"
                    id="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Experience"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="qualification"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Qualification
                  </label>
                  <input
                    type="text"
                    name="qualification"
                    id="qualification"
                    value={formData.qualification}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Qualification"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="skills"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Skills
                  </label>
                  <input
                    type="text"
                    name="skills"
                    id="skills"
                    value={formData.skills}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Skills"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="applied"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Applied For
                  </label>
                  <input
                    type="text"
                    name="applied"
                    id="applied"
                    value={formData.applied}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Applied for"
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="message"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    rows="3"
                    name="message"
                    onChange={handleChange}
                    id="message"
                    value={formData.message}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    placeholder="Enter your message..."
                    required
                  ></textarea>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="file"
                    className="text-sm font-medium text-gray-900 block mb-2"
                  >
                    Upload File
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleFileChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                    required
                  />
                </div>
              </div>
              <div className="p-6 border-t border-gray-200 rounded-b w-full">
                <button
                disabled={loading}
                  className="text-white bg-red-500 w-full hover:bg-black transition-all duration-200 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  type="submit"
                >
                   {loading ? "Sending..." : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Careers;
