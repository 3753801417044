import { Carousel, Typography, Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function ClientCarousel() {
  const navigate = useNavigate();
  return (
    <Carousel className="h-[500px] w-full">
      <div className="relative h-full w-full">
        <img
          src="https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="image 2"
          loading="lazy"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
          <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-3xl lg:text-4xl"
            >
              Excellence Through Partnership
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 text-sm md:text-xl"
            >
              Join a prestigious list of industry leaders who trust us for their
              staffing needs. Access top-tier talent and propel your
              organization forward.
            </Typography>
            <div className="flex">
              <Button onClick={() => navigate("/")} size="md" color="red">
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <img
          src="https://images.unsplash.com/photo-1549923746-c502d488b3ea?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="image 3"
          loading="lazy"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full items-end bg-black/75">
          <div className="w-3/4 pl-12 pb-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-3xl lg:text-4xl"
            >
              Excellence Through Partnership
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 text-sm md:text-xl"
            >
              Join a prestigious list of industry leaders who trust us for their
              staffing needs. Access top-tier talent and propel your
              organization forward.
            </Typography>
            <div className="flex">
              <Button onClick={() => navigate("/")} size="md" color="red">
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}
