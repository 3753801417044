import React, { useState } from "react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { Mail, MapPin } from "lucide-react";
import Parallax from "react-next-parallax";
import { ContactCarousel } from "../Carousel/ContactCarousel";
import { userContact } from "../../api/function";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [loading, setIsloading] = useState(false);

  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsloading(true);
    console.log(formData);

    userContact(formData)
      .then((response) => {
        toast.success(response?.message, {
          className: "text-[12px] !shadow-lg !rounded-xl",
        });

        setFormData({
          phoneNumber: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        toast.error("Something went wrong!", {
          className: "text-[12px] !shadow-lg !rounded-xl",
        });
        console.error("Error during form submission:", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   setIsloading(true);

  //   fetch('http://localhost:4000/api/v1/contact-us', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(formData),
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw new Error('Failed to submit form');
  //       }
  //     })
  //     .then((data) => {
  //       if (data?.code === 200) {
  //         toast.success(data?.message, {
  //           className: "text-[12px] !shadow-lg !rounded-xl",
  //         });
  //         setFormData({
  //           phoneNumber: "",
  //           email: "",
  //           message: "",
  //         });
  //       } else {
  //         toast.error(data?.message, {
  //           className: "text-[12px] !shadow-lg !rounded-xl",
  //         });
  //       }
  //     })
  //     .catch((error) => {

  //       toast.error("Something went wrong!", {
  //         className: "text-[12px] !shadow-lg !rounded-xl",
  //       });
  //       console.error("Error during form submission:", error);
  //     })
  //     .finally(() => {
  //       setIsloading(false);
  //     });
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Navbar />
      <div className="hidden md:block">
        <ContactCarousel />
      </div>
      <section class="bg-white dark:bg-gray-900">
        <div class="container px-6 py-8 mx-auto">
          <div data-aos="zoom-in" className="flex flex-col gap-4">
            <div className="flex flex-col items-center justify-center">
              <p class="font-bold text-2xl text-black dark:text-blue-400">
                CONTACT US
              </p>
              <div className="h-1 w-1/12 bg-red-500 rounded-md"></div>
              <p class="mt-3 text-gray-700 dark:text-gray-400 text-center">
                Thank you for your interest in AVD Staffing Solution. To request
                information or send general feedback, please complete the
                following form:
              </p>
            </div>

            <div class="grid grid-cols-1 items-center gap-12 mt-10 lg:grid-cols-2">
              <div className="flex flex-col gap-3">
                <Parallax y={[-10, 10]} className="">
                  <div className="flex flex-col gap-4 items-center justify-center rounded-md p-12 border border-gray-400 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                    <div className="rounded-full bg-blue-gray-500/20 border border-gray-500  p-2 ">
                      <MapPin color="red" />
                    </div>
                    <h4 className="font-bold text-gray-600">Our Address</h4>
                    <p className="text-gray-600 text-sm text-center">
                      A556/1B, Second Floor, New Ashok Nagar, Delhi - 110096
                    </p>
                  </div>
                </Parallax>
                <Parallax y={[-10, 10]} className="">
                  <div className="flex flex-col gap-4 items-center justify-center rounded-md p-12 shadow-[0_3px_10px_rgb(0,0,0,0.2)] border border-gray-400">
                    <div className="rounded-full bg-blue-gray-500/20 border border-gray-500  p-2 ">
                      <Mail color="red" />
                    </div>
                    <h4 className="font-bold text-gray-600">Email Us</h4>
                    <div className="flex flex-col items-center justify-center">
                      <p className="text-gray-600 text-sm text-center">
                        <span className="font-[500] text-black">
                          Job Search:
                        </span>{" "}
                        <a href="mailto:aditya@avdstaffingsolutions.com">
                          aditya@avdstaffing.com
                        </a>
                      </p>
                      <p className="text-gray-600 text-sm">
                        <span className="font-[500] text-black">Info:</span>{" "}
                        <a href="mailto:vivek@avdstaffing.com">
                          vivek@avdstaffing.com
                        </a>
                      </p>
                    </div>
                  </div>
                </Parallax>
                <div></div>
              </div>

              <div class="p-4 py-6 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8">
                <form onSubmit={handleSubmit}>
                  <div class="-mx-2 md:items-center md:flex">
                    <div class="flex-1 px-2">
                      <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                        Phone
                      </label>
                      <input
                        type="text"
                        placeholder="Phone number "
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>
                  </div>

                  <div class="mt-4">
                    <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      Email address
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your emial address.."
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div class="w-full mt-4">
                    <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      Message
                    </label>
                    <textarea
                      class="block w-full h-22 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-36 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <button
                    disabled={loading}
                    class="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
