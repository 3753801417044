import React from "react";
import { CheckCheck } from "lucide-react";
import Parallax from "react-next-parallax";

export function Insight() {
  return (
    <section className="container px-4 py-12 mx-auto">
      <div
        data-aos="zoom-in-up"
        className="grid content-center lg:grid-cols-2 md:grid-cols-1 gap-10 gap-x-12"
      >
        <div className="w-full md:order-1">
          <h1 className="text-[22px] font-bold tracking-tight text-black md:text-[26px] lg:text-[28px]">
            Industry-Focused HR Solutions
          </h1>
          <p className="mt-3 italic text-gray-700 text-base font-rubik">
            {/* AVD Staffing takes pride in serving a wide array of industries,
            offering customized HR solutions to meet the unique needs of each
            sector. Our commitment to excellence has made us a trusted partner
            to businesses across various industries, helping them achieve their
            strategic goals through our comprehensive HR services. */}
            At AVD Staffing, we understand that each industry has its own unique
            challenges and requirements when it comes to HR. That's why we offer
            customized HR solutions designed to meet the specific needs of your
            sector. Our industry-focused approach and commitment to excellence
            make us the ideal partner for businesses across a range of
            industries.
          </p>

          <p className="mt-5 text-base font-rubik">
            With our industry-focused approach and commitment to excellence, AVD
            Staffing has become a trusted partner for businesses looking to
            enhance their HR capabilities and achieve success in their
            respective industries
          </p>

          <p className="mt-4 font-[600] text-xl leading-6">
            Industries We Serve
          </p>
          <ul data-aos="slide-right" className=" mt-3 font-rubik">
            <div className="flex gap-2 mt-3">
              <CheckCheck color="#ec0e0e" />
              <li>Technology & IT Services</li>
            </div>
            <div className="flex gap-2 mt-2">
              <CheckCheck color="#ec0e0e" />
              <li>Financial Services & Fintech</li>
            </div>
            <div className="flex gap-2 mt-2">
              <CheckCheck color="#ec0e0e" />
              <li>Payment Solutions</li>
            </div>
            <div className="flex gap-2 mt-2">
              <CheckCheck color="#ec0e0e" />
              <li>Consumer Services, Education & Retail</li>
            </div>
            <div className="flex gap-2 mt-2">
              <CheckCheck color="#ec0e0e" />
              <li>Telecommunications, Media & Entertainment</li>
            </div>
            <div className="flex gap-2 mt-2">
              <CheckCheck color="#ec0e0e" />
              <li>
                Business Process Outsourcing (BPO) & IT Enabled Services (ITES)
              </li>
            </div>
          </ul>
        </div>
        <div className=" content-center order-1 md:order-1 lg:order-2">
          {/* <Parallax y={[-10, 10]} className="w-full h-full"> */}
            <img
              className="object-cover w-full rounded-md h-[600px]"
              src="./images/insight.jpg"
              alt="Newsletter"
            />
          {/* </Parallax> */}
        </div>
      </div>
    </section>
  );
}
