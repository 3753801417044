import React, { useState } from "react";
import { Navbar } from "../Navbar";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-tailwind/react";
import { ChevronRight } from "lucide-react";
import { Users, BookUser, Boxes } from "lucide-react";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { Footer } from "../Footer";
import Parallax from "react-next-parallax";
import Faqs from "../Faqs";

const AboutUs = () => {
  const navigate = useNavigate();

  const info =
    "AVD Staffing is a dynamic and innovative recruitment company, unwaveringly committed to delivering exceptional HR solutions tailored specifically to the low to mid-level management sector. Our dedicated team is driven by a shared passion for excellence and a genuine enthusiasm for providing our clients with the best possible service. We pride ourselves on our ability to understand the unique needs of both our clients and candidates, ensuring a perfect match every time. Our comprehensive approach combines industry expertise, cutting-edge technology, and personalized service to create effective and efficient staffing solutions. At AVD Staffing, we are more than just recruiters; we are partners in your success, committed to fostering long-term relationships and contributing to the growth and development of your organization. Trust AVD Staffing to deliver unparalleled service, exceptional talent, and innovative HR solutions that propel your business forward.";

  const [readmore, setReadmore] = useState(false);

  const description = readmore ? info : `${info.substring(0, 200)}....`;
  function readmoreHandler() {
    setReadmore(!readmore);
  }

  const handleClick = () => {
    navigate("/services");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Navbar />
      <section className="h-[400px] md:h-[500px] w-full">
        <div className="relative h-full w-full">
          <img
            src="./images/aboutMainImg.png"
            loading="lazy"
            alt="img2"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
            <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
              <Typography
                variant="h1"
                color="white"
                className="mb-4 text-2xl md:text-3xl lg:text-4xl"
              >
                Who we are ?
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="mb-12 opacity-80 text-sm md:text-xl"
              >
                Read about our journey from a visionary idea to becoming a
                trusted partner for businesses seeking excellence and
                transformation.
              </Typography>
              <div className="flex">
                <Button onClick={() => navigate("/")} size="md" color="red">
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="welcome"
        className="container px-4 pt-24 pb-16   mx-auto "
        data-aos="zoom-in"
      >
        <div className="text-[22px] text-center font-bold tracking-tight mx-auto md:text-[26px] lg:text-[28px]">
          WELCOME TO AVD STAFFING SOLUTIONS PVT LTD
        </div>
        <div className="h-1 w-1/12 bg-red-500 rounded-md mx-auto"></div>
        <div className="mt-4 text-base font-rubik mx-auto text-center">
          {description}
          <span
            className="read-more text-blue-600 cursor-pointer"
            onClick={readmoreHandler}
          >
            {readmore ? `Show Less` : `Read \More`}
          </span>
        </div>
        <Button
          className="flex w-28 justify-center items-center rounded-lg  bg-red-900 text-white hover:bg-black  cursor-pointer m-5 mx-auto"
          onClick={handleClick}
        >
          <p className="font-rubik">Explore</p>
          <ChevronRight size={15} />
        </Button>
      </section>

      <div className="bg-[#252525]">
        <section className="container px-4 py-12 mx-auto">
          <div className="grid content-center lg:grid-cols-3 md:grid-cols-2 gap-4 gap-x-12">
            <div
              data-aos="slide-right"
              className="lg:col-span-1 content-center lg:row-span-2 md:col-span-full"
            >
              <h1 className="text-[22px] font-bold tracking-tight text-white md:text-22px] lg:text-[22px]">
                Introduction Of Company
              </h1>
              <p className="mt-4 text-sm font-rubik text-white">
                AVD Staffing is a dynamic recruitment company committed to
                delivering exceptional HR solutions tailored to the low to
                mid-level management sector. Our team is driven by a shared
                passion for excellence and a genuine enthusiasm for providing
                our clients with the best possible service. We are known for our
                thorough and personalized approach to recruitment, ensuring that
                every client receives a bespoke solution that meets their unique
                requirements.
              </p>
              <div className="">
                <Button
                  className="flex w-28 justify-center items-center rounded-lg  bg-red-900 text-white hover:bg-black  cursor-pointer mt-5"
                  onClick={handleClick}
                >
                  <p className="font-rubik">Explore</p>
                  <ChevronRight size={15} />
                </Button>
              </div>
            </div>
            {/* Add Paraleex in this part only */}

            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 transition-all duration-200 rounded-lg "
            >
              <Parallax y={[-20, 20]}>
                {/* <Users color="red" size={30} /> */}
                <Users color="red" size={30} />
                <p className="font-[600] text-[18px] mt-1 font-mulish text-white">
                  Expert Team
                </p>
                <p className="mt-4 text-sm font-rubik text-gray-400">
                  Our team of skilled professionals with diverse HR and
                  recruitment expertise is dedicated to understanding your
                  unique needs and delivering tailored solutions that exceed
                  expectations.
                </p>
              </Parallax>
            </div>

            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 rounded-lg"
            >
              <Parallax y={[-20, 20]} className="w-full h-full">
                <BookUser color="red" size={30} />
                <p className="font-[600] text-[18px] mt-1 font-mulish text-white">
                  Industry-Leading Recruiters
                </p>
                <p className="mt-4 text-sm text-gray-400 font-rubik">
                  As industry leaders in recruitment, our national executive
                  recruiters have a proven success in securing top talent
                  nationwide. We connect you with the best candidates for
                  long-term success and growth.
                </p>
              </Parallax>
            </div>
            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 rounded-lg"
            >
              <Parallax y={[-20, 20]} className="w-full h-full">
                <MdOutlineLibraryBooks color="red" size={30} />
                <p className="font-[600] text-[18px] mt-1 font-mulish text-white">
                  Dependable & Cost-Effective
                </p>
                <p className="mt-4 text-sm text-gray-400 font-rubik">
                  Our reputation for reliability and cost-effectiveness is built
                  on trust and transparency. We deliver results within budget
                  and on time, ensuring every client receives top-tier service
                  at a competitive price.
                </p>
              </Parallax>
            </div>
            <div
              data-aos="zoom-in-up"
              className="p-4 shadow-2xl shadow-blue-500/20 rounded-lg"
            >
              <Parallax y={[-20, 20]} className="w-full h-full">
                {/* <Users color="red" size={30} /> */}
                <Boxes color="red" size={30} />
                <p className="font-[600] text-[18px] font-mulish mt-1 text-white">
                  Nationwide Service Reach
                </p>
                <p className="mt-4 text-sm text-gray-400 font-rubik">
                  With our nationwide service scope, we can connect you with top
                  talent anywhere. Whether you're in a major city or a rural
                  area, our resources and expertise will help you find the right
                  candidates.
                </p>
              </Parallax>
            </div>
            {/* End Paraleex in this part only */}
          </div>
        </section>
      </div>

      <section>
        <div data-aos="zoom-in-up" className="container px-4 py-8 mx-auto">
          <img src="/images/aboutUS.jpg" alt="about" loading="lazy" />
        </div>
      </section>

      <section className="bg-gray-100 py-8">
        <div data-aos="zoom-in-up" className="container mx-auto px-4 py-12">
          <div className="flex flex-col  justify-center items-center  mx-auto p-2  rounded-md">
            <p className="font-bold lg:text-4xl text-3xl p-2 text-center">
              Our steps towards our service
            </p>
            <div className="h-1 w-1/12 rounded-md bg-red-500"></div>
            <p className="text-center m-5 pt-5 font-rubik">
              At AVD Staffing, we meticulously match candidates to your needs.
              We start by understanding your requirements and company culture,
              then source and screen candidates. We facilitate the interview
              process and ensure a seamless onboarding experience. Trust AVD
              Staffing for tailored HR solutions.
            </p>
            <img
              src="./images/ourSteps.png"
              alt="steps"
              className="h-auto rounded-lg w-full"
              loading="lazy"
            ></img>
          </div>
        </div>
      </section>

      <Faqs />

      <Footer />
    </>
  );
};

export default AboutUs;
