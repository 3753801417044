import React from "react";
import { Copy, ArrowRight } from "lucide-react";
import { Navbar } from "../Navbar";
import { FaCalendarAlt, FaUsers } from "react-icons/fa";
import { IoBagHandle, IoMail } from "react-icons/io5";
import { MdContactMail } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { Footer } from "../Footer";
import { ProcessCarousel } from "../Carousel/ProcessCarousel";

const OurProcess = () => {
  return (
    <>
      <Navbar />
      <div className="hidden md:block">
        <ProcessCarousel />
      </div>
      <div className="container px-6 py-8 mx-auto">
        <div className="flex flex-col items-center justify-center">
          <p class="font-bold text-2xl text-black dark:text-blue-400">
            Our Process
          </p>
          <div className="h-1 w-1/12 bg-red-500 rounded-md"></div>
          <p class="mt-3 text-gray-700 dark:text-gray-400 text-center font-rubik">
            At AVD Staffing Solution, we pride ourselves on a meticulous and
            client-focused approach to recruitment. Our process is designed to
            ensure we find the perfect match for both our clients and
            candidates. Here's how we do it:
          </p>
        </div>

        <div
          data-aos="zoom-in"
          className="mt-8 mb-8 grid grid-cols-1 gap-8 md:grid-cols-2 xl:mt-12 xl:grid-cols-3 xl:gap-16"
        >
          <div className="space-y-3 p-3 rounded-lg cursor-pointer hover:bg-blue-100/40 transition-all duration-300 hover:scale-105  shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
            <span className="inline-block rounded-full bg-gray-100 p-3 text-black">
              <FaUsers size="25" color="red" />
            </span>
            <h1 className="text-lg font-semibold capitalize text-black">
              DISCUSS VACANCY NEEDS
            </h1>
            <p className="text-sm text-gray-700">
              A dedicated campaign manager will work with you, successfully
              identify your business and employee needs; and will guide you
              every step of the way.
            </p>
            <a
              href="#"
              className="-mx-1 inline-flex transform items-center text-sm font-semibold capitalize text-black transition-colors duration-300 hover:underline hover:text-blue-400"
            >
              <span className="mx-1">read more</span>
              <ArrowRight size={16} />
            </a>
          </div>

          <div className="space-y-3 p-3 rounded-lg cursor-pointer hover:bg-blue-100/40 transition-all duration-300 hover:scale-105  shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
            <span className="inline-block rounded-full bg-gray-100 p-3 text-black">
              <IoBagHandle size="25" color="red" />
            </span>
            <h1 className="text-lg font-semibold capitalize text-black">
              ADVERTISE ON 20 JOB BOARDS
            </h1>
            <p className="text-sm text-gray-700">
              We will advertise the vacancy to active job seekers on relevant,
              paid/free multiple generic, niche and the ever growing social
              media.
            </p>
            <a
              href="#"
              className="-mx-1 inline-flex transform items-center text-sm font-semibold capitalize text-black transition-colors duration-300 hover:underline hover:text-blue-400"
            >
              <span className="mx-1">read more</span>
              <ArrowRight size={16} />
            </a>
          </div>

          <div className="space-y-3 p-3 rounded-lg cursor-pointer hover:bg-blue-100/40 transition-all duration-300 hover:scale-105  shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
            <span className="inline-block rounded-full bg-gray-100 p-3 text-black">
              <IoMail size="25" color="red" />
            </span>
            <h1 className="text-lg font-semibold capitalize text-black">
              MASS MAIL CANDIDATES
            </h1>
            <p className="text-sm text-gray-700">
              We will mass email passive candidates on relevant databases.
            </p>
            <a
              href="#"
              className="-mx-1 inline-flex transform items-center text-sm font-semibold capitalize text-black transition-colors duration-300 hover:underline hover:text-blue-400"
            >
              <span className="mx-1">read more</span>
              <ArrowRight size={16} />
            </a>
          </div>

          <div className="space-y-3 p-3 rounded-lg cursor-pointer hover:bg-blue-100/40 transition-all duration-300 hover:scale-105  shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
            <span className="inline-block rounded-full bg-gray-100 p-3 text-black">
              <MdContactMail size="25" color="red" />
            </span>
            <h1 className="text-lg font-semibold capitalize text-black">
              CV'S FILTERED
            </h1>
            <p className="text-sm text-gray-700">
              All CV's received will be human filtered by our experienced team
              who will email only relevant CV's to your inbox.
            </p>
            <a
              href="#"
              className="-mx-1 inline-flex transform items-center text-sm font-semibold capitalize text-black transition-colors duration-300 hover:underline hover:text-blue-400"
            >
              <span className="mx-1">read more</span>
              <ArrowRight size={16} />
            </a>
          </div>

          <div className="space-y-3 p-3 rounded-lg cursor-pointer hover:bg-blue-100/40 transition-all duration-300 hover:scale-105  shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
            <span className="inline-block rounded-full bg-gray-100 p-3 text-black">
              <FaCalendarAlt size="25" color="red" />
            </span>
            <h1 className="text-lg font-semibold capitalize text-black">
              INTERVIEW SCHEDULED
            </h1>
            <p className="text-sm text-gray-700">
              Depending on the package chosen, we also conduct initial
              telephonic interview and schedule for next round of interviews as
              well.
            </p>
            <a
              href="#"
              className="-mx-1 inline-flex transform items-center text-sm font-semibold capitalize text-black transition-colors duration-300 hover:underline hover:text-blue-400"
            >
              <span className="mx-1">read more</span>
              <ArrowRight size={16} />
            </a>
          </div>

          <div className="space-y-3 p-3 rounded-lg cursor-pointer hover:bg-blue-100/40 transition-all duration-300 hover:scale-105  shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
            <span className="inline-block rounded-full bg-gray-100 p-3 text-black">
              <AiFillLike size="25" color="red" />
            </span>
            <h1 className="text-lg font-semibold capitalize text-black">
              HIRE THE BEST
            </h1>
            <p className="text-sm text-gray-700">
              You interview and hire only the best, it's that simple. The best
              part is you can hire multiple candidates at the same cost.
            </p>
            <a
              href="#"
              className="-mx-1 inline-flex transform items-center text-sm font-semibold capitalize text-black transition-colors duration-300 hover:underline hover:text-blue-400"
            >
              <span className="mx-1">read more</span>
              <ArrowRight size={16} />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default OurProcess;
