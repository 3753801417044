import React, { useState, useEffect } from "react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { ClientCarousel } from "../Carousel/ClientCarousel";

const OurClients = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;

  const data = [
    { title: "Neosoft Technologies", image: "./images/neoSoft.png" },
    { title: "Spottabl", image: "./images/spottabl.png" },
    { title: "Hiringplug", image: "./images/hiringPlug.png" },
    { title: "Parashar Infotech", image: "./images/parasharInfo.png" },
    { title: "Parashar Ayurveda", image: "./images/parasharAyur.png" },
    { title: "Alfa Square", image: "./images/alfasquare.jpg" },
  ];

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const getCurrentPageData = () => {
    const startIndex = currentPage * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextPage();
    }, 2000);
    return () => clearInterval(interval);
  }, [totalPages]);

  return (
    <>
      <Navbar />
      <div className="hidden md:block">
        <ClientCarousel />
      </div>
      <section className="w-full">
        <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 md:py-16 lg:px-8 w-full">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-[600] text-gray-900 sm:text-4xl font-mulish">
              Client Spotlight: Success Stories
            </h2>
            <p className="mt-3 text-gray-700 dark:text-gray-400 text-center font-rubik">
              Dedicated to providing you with the tools and techniques necessary
              to enhance your revenue streams and foster business growth. Trust
              us to be your strategic partner in achieving your business
              objectives.
            </p>
          </div>

          <div className="mt-8 sm:mt-12 w-full">
            <div className="overflow-hidden">
              <div
                className="flex transition-transform duration-1000 ease-in-out"
                style={{ transform: `translateX(-${currentPage * 100}%)` }}
              >
                {Array(totalPages)
                  .fill()
                  .map((_, pageIndex) => (
                    <div className="flex-shrink-0 w-full flex">
                      {getCurrentPageData().map((item, index) => (
                        <div
                          key={index}
                          className="w-1/3 px-4 py-8 text-center"
                        >
                          <img
                            src={item.image}
                            alt={item.title}
                            className="mx-auto mb-2 h-20 w-30 object-contain lazy  "
                          />
                          <dt className="order-last text-lg font-medium text-gray-500">
                            {item.title}
                          </dt>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OurClients;
