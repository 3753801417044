import React, { useState } from "react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import ContactUs from "../Pages/ContactUs";
import Hero from "../Hero";
import { Intro } from "../Intro";
import { Insight } from "../Insight";
import { Mail, MapPin } from "lucide-react";
import { Button } from "@material-tailwind/react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Parallax from "react-next-parallax";
import { userContact } from "../../api/function";
import { toast } from "react-toastify";

const Home = () => {
  const navigate = useNavigate();
  const [loading, setIsloading] = useState(false);
  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsloading(true);
    console.log(formData);
    userContact(formData)
      .then((response) => {
        toast.success(response?.message, {
          className: "text-[12px] !shadow-lg !rounded-xl",
        });
        setFormData({
          phoneNumber: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        toast.error("Something went wrong!", {
          className: "text-[12px] !shadow-lg !rounded-xl",
        });
        console.error("Error during form submission:", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const scrollToWelcome = () => {
    const welcomeSection = document.getElementById("welcome");
    if (welcomeSection) {
      welcomeSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = () => {
    navigate("/about-us");
  };

  return (
    <>
      {/* Navbar section */}
      <Navbar />
      {/* Hero Section */}
      <Hero scrollToWelcome={scrollToWelcome} />
      {/* Welcome section */}
      <section
        id="welcome"
        className="container px-4 pt-36 pb-16   mx-auto "
        data-aos="zoom-in"
      >
        <div className="text-[22px] text-center font-bold tracking-tight mx-auto md:text-[26px] lg:text-[28px]">
          WELCOME TO AVD STAFFING
        </div>
        <div className="h-1 w-1/12 bg-red-500 rounded-md mx-auto"></div>
        <div className="mt-4 text-base font-rubik mx-auto text-center">
          AVD Staffing is a dynamic recruitment company committed to delivering
          exceptional HR solutions tailored to the low to mid-level management
          sector. Our team is driven by a shared passion for excellence and a
          genuine enthusiasm for providing our clients with the best possible
          service
        </div>
        <Button
          className="flex w-28 justify-center items-center rounded-lg  bg-red-900 text-white hover:bg-black  cursor-pointer m-5 mx-auto"
          onClick={handleClick}
        >
          <p className="font-rubik">More</p>
          <ChevronRight size={15} />
        </Button>
      </section>
      {/* Service Section */}
      <Intro />
      {/* Insighht Section */}
      <Insight />
      {/* Contact Section */}
      <section class="bg-[#252525] dark:bg-gray-900">
        <div class="container px-6 py-12 mx-auto">
          <div className="flex flex-col gap-4 lg:order-1 md:order-2 sm:order-2">
            <div className="flex flex-col items-center justify-center">
              <p class="font-bold text-2xl text-white dark:text-blue-400">
                CONTACT US
              </p>
              <div className="h-1 w-1/12 bg-red-500 rounded-md"></div>
              <p class="mt-3 text-white text-center dark:text-gray-400">
                Thank you for your interest in AVD Staffing Solution. To request
                information or send general feedback, please complete the
                following form:
              </p>
            </div>

            <div class="grid grid-cols-1 items-center gap-12 mt-16 lg:grid-cols-2">
              <div className="flex flex-col gap-3 order-1 md:order-2 sm:order-2">
                <div className="rounded-md bg-blue-gray-500/20 shadow-xl shadow-blue-500/20 p-2 ">
                  <Parallax y={[-10, 10]} className="w-full h-full">
                    <div className="flex flex-col gap-4 items-center justify-center rounded-lg p-12 shadow-2xl shadow-blue-500/20">
                      <div className="rounded-full bg-blue-gray-500/20 shadow-xl shadow-blue-500/20 border border-gray-500  p-2 ">
                        <MapPin color="red" />
                      </div>
                      <h4 className="font-bold text-white">Our Address</h4>
                      <p className="text-white text-sm text-center">
                        A556/1B, Second Floor, New Ashok Nagar, Delhi - 110096
                      </p>
                    </div>
                  </Parallax>
                </div>
                <div className="rounded-md bg-blue-gray-500/20 shadow-xl shadow-blue-500/20 p-2 ">
                  <Parallax y={[-10, 10]} className="w-full h-full">
                    <div className="flex flex-col gap-4 items-center justify-center rounded-lg p-12 shadow-2xl shadow-blue-500/20">
                      <div className="rounded-full bg-blue-gray-500/20 shadow-xl shadow-blue-500/20 border border-gray-500  p-2 ">
                        <Mail color="red" />
                      </div>
                      <h4 className="font-bold text-white">Email Us</h4>
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-white text-sm text-center">
                          <span className="font-[500] text-gray-200 text-center ">
                            Job Search:
                          </span>{" "}
                          <span>
                            <a href="mailto:aditya@avdstaffingsolutions.com">
                              aditya@avdstaffing.com
                            </a>
                          </span>
                        </p>
                        <p className="text-white text-sm">
                          <span className="font-[500] text-gray-200 text-center">
                            Info:
                          </span>{" "}
                          <a href="mailto:vivek@avdstaffing.com">
                            vivek@avdstaffing.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </Parallax>
                </div>
                <div></div>
              </div>

              <div class="p-4 py-6 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8 lg:order-2 md:order-1 sm:order-1">
                <form onSubmit={handleSubmit}>
                  <div class="-mx-2 md:items-center md:flex">
                    <div class="flex-1 px-2">
                      <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                        Phone
                      </label>
                      <input
                        type="text"
                        placeholder="Phone number "
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>
                  </div>

                  <div class="mt-4">
                    <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      Email address
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your emial address.."
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div class="w-full mt-4">
                    <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      Message
                    </label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      class="block w-full h-22 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-36 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Message"
                    ></textarea>
                  </div>

                  <button  disabled={loading} class="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                  {loading ? "Sending..." : "Send"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Addres Section */}
      <section class="bg-white dark:bg-gray-900">
        <div data-aos="zoom-in" class="container px-6 py-12 mx-auto">
          <div class="text-center ">
            <h1 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">
              We look forward to hearing from you!
            </h1>

            <p class="mt-3 text-gray-500 dark:text-gray-400">
              Contact us today via email, phone, or visit our office.
            </p>
          </div>

          <img
            class="object-cover w-full h-64 mt-10 rounded-lg lg:h-96"
            src="./images/Ba.jpg"
            loading="lazy"
            alt=""
          />

          <div class="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-3 sm:grid-cols-2 ">
            {/* <Parallax className="h-full w-full"> */}
            <div class="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-4 rounded-lg bg-blue-50 md:p-6 dark:bg-gray-800">
              <span class="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </span>

              <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">
                Chat to sales
              </h2>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Speak to our friendly team.
              </p>
              <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">
                <a href="mailto:– vivek@avdstaffing.com">
                  vivek@avdstaffing.com
                </a>
              </p>
            </div>
            {/* </Parallax> */}

            <div class="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-4 rounded-lg bg-blue-50 md:p-6 dark:bg-gray-800">
              <span class="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </span>

              <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">
                Visit us
              </h2>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Visit our office HQ..
              </p>
              <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">
                A556/1B, Second Floor, New Ashok Nagar, Delhi - 110096
              </p>
            </div>

            <div class="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-4 rounded-lg bg-blue-50 md:p-6 dark:bg-gray-800">
              <span class="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
              </span>

              <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">
                Call us
              </h2>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Mon-Fri from 8am to 5pm.
              </p>
              <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">
                +91 8273968056
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
